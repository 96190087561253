<template>
  <div class="pa-5 pt-0 d-flex flex-column body-2">
    <template v-if="isSubscribed">
      <div>
        Seu plano atual é:
        <span class="font-weight-bold secondary--text">
          {{ currentSubscription.productTypeLabel }}
        </span>
      </div>
      <div
        v-if="currentSubscription.nextBillingTime"
        class="caption grey--text"
      >
        A próxima cobrança será realizada em
        {{ currentSubscription.nextBillingTime }}
      </div>
    </template>
    <template v-else>
      <div>Nenhum plano ativado.</div>
    </template>
    <v-btn
      rounded
      color="primary"
      class="mt-10"
      large
      :disabled="isStatusCancelled"
      @click="$router.push({ name: 'subscriptions.plans' })"
    >
      <span>{{ labelButton }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SubscriptionCard",
  props: {
    currentSubscription: {
      type: Object,
      default: () => ({
        nextBillingTime: "",
        productTypeLabel: "",
      }),
    },
  },
  computed: {
    isSubscribed() {
      return !!this.currentSubscription.status;
    },
    isStatusActive() {
      return this.currentSubscription.status === "ACTIVE";
    },
    isStatusCancelled() {
      return this.currentSubscription.status === "CANCELED";
    },
    labelButton() {
      if (this.isStatusActive) {
        return "Alterar plano";
      } else if (this.isStatusCancelled) {
        return "Assinatura cancelada";
      } else {
        return "Contratar plano";
      }
    },
  },
};
</script>

<style scoped></style>
