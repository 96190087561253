<template>
  <div>
    <v-row class="pt-0">
      <v-col cols="12" sm="6" lg="3">
        <dashboard-card title="Realizar nova terapia">
          <v-btn class="primary" x-large rounded @click="newTherapy">
            Clique aqui
          </v-btn>
        </dashboard-card>
        <v-spacer />
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <dashboard-card title="Terapias realizadas nos últimos 7 dias">
          <dashboard-text :text="performedTherapiesLast7Days" />
        </dashboard-card>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <dashboard-card title="Terapias realizadas hoje">
          <dashboard-text :text="performedTherapiesToday" />
        </dashboard-card>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <dashboard-card title="Agendamentos para hoje">
          <dashboard-text :text="confirmedAppointmentsToday" />
        </dashboard-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <dashboard-card title="Agendamentos">
          <simplified-appointments-calendar :events="calendarEvents" />
        </dashboard-card>
      </v-col>
      <v-col cols="12" lg="6">
        <dashboard-card title="Histórico de rastreios">
          <therapies-list
            :therapies="therapiesHistory"
            name-route-detailed="therapys"
          />
        </dashboard-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <dashboard-card title="Agendamentos pendentes de aprovação">
          <appointments-to-approve class="text-left" />
        </dashboard-card>
      </v-col>
      <v-col cols="12" lg="6">
        <dashboard-card title="Planos">
          <subscription-card :current-subscription="currentSubscription" />
        </dashboard-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getTherapistDashboardData } from "@/services/external/dashboard.service";
import SubscriptionCard from "@/domains/dashboard/presentation/components/SubscriptionCard";

export default {
  components: {
    SubscriptionCard,
    AppointmentsToApprove: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/ToApprove"
      ),
    DashboardCard: () =>
      import("@/domains/dashboard/presentation/components/DashboardCard"),
    DashboardText: () =>
      import("@/domains/dashboard/presentation/components/DashboardText"),
    TherapiesList: () =>
      import(
        "@/domains/dashboard/presentation/components/SimplifiedTherapiesList"
      ),
    SimplifiedAppointmentsCalendar: () =>
      import(
        "@/domains/dashboard/presentation/components/SimplifiedAppointmentsCalendar"
      ),
  },
  data: () => ({
    performedTherapiesToday: 0,
    performedTherapiesLast7Days: 0,
    confirmedAppointmentsToday: 0,
    therapiesHistory: [],
    calendarEvents: [],
    currentSubscription: {
      nextBillingTime: "",
      productTypeLabel: "",
    },
  }),
  computed: {
    subscriptionProductType() {
      return this.$store.getters["login/subscriptionProductType"];
    },
  },
  mounted() {
    if (!this.subscriptionProductType) {
      this.$router.push({ name: "subscriptions.plans" });
    }
  },
  created() {
    this.fillData();
  },
  methods: {
    async fillData() {
      const { data } = await getTherapistDashboardData();
      const {
        performedTherapiesToday,
        performedTherapiesLast7Days,
        confirmedAppointmentsToday,
        therapiesHistory,
        calendarEvents,
        currentSubscription,
      } = data;
      this.performedTherapiesToday = performedTherapiesToday;
      this.performedTherapiesLast7Days = performedTherapiesLast7Days;
      this.confirmedAppointmentsToday = confirmedAppointmentsToday;
      this.therapiesHistory = therapiesHistory;
      this.calendarEvents = calendarEvents;
      this.currentSubscription = currentSubscription;
    },
    newTherapy() {
      this.$store.dispatch("therapy/newTherapy");
    },
  },
};
</script>

<style scoped></style>
